import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaBarcode, FaSquarespace } from "react-icons/fa";
import { AiFillHome, AiFillDashboard } from "react-icons/ai";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import NavItem from "./NavItem";

import GavelIcon from '@material-ui/icons/Gavel';

import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs"
import { TfiAnnouncement } from "react-icons/tfi"
import { AiOutlineFileText, AiFillFileText } from "react-icons/ai"
import { BiLockOpenAlt, BiNews, BiMoneyWithdraw } from "react-icons/bi"
import { ImBlogger } from "react-icons/im"




function renderNavItems({ items, pathname, depth = 0, state, setSelectedTab }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, state, setSelectedTab }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, state, setSelectedTab }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          state,
          setSelectedTab


        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        tabview={item.tabview}
        key={key}
        title={item.title}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: theme.palette.background.header,
    background: '#232B3B'
  },
  desktopDrawer: {
    top: "76px",
    width: "250px",
    height: "calc(100% - 115px)",
    margin: "5px 10px 10px 15px",
    // background: theme.palette.background.header,
    background: '#232B3B',

    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginTop: "50px",
    marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
  },
  sideMenuBox: {
    // background:'#232B3B',
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      background: '#232B3B !important'
    }

  }
}));

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillDashboard,
        href: "/dashboard",
        tabview: "Arbitrage"
      },
      {
        title: "User Management",
        icon: BsFillPersonFill,
        href: "/user-list",
        tabview: "Arbitrage"
      },
      {
        title: "Matching Tree",
        icon: BsFillPersonFill,
        href: "/binary-tree",
        tabview: "Arbitrage"
      },
      {
        title: "KYC Management",
        icon: FaRegMoneyBillAlt,

        href: "/kyc-list",
        tabview: "Arbitrage"
      },
      {
        title: "Withdraw Management",
        icon: BiMoneyWithdraw,

        href: "/withdraw-list",
        tabview: "Arbitrage"
      },
      {
        title: "Transaction Management",
        icon: GavelIcon,

        href: "/transaction-management",
        tabview: "Arbitrage"
      },
      {
        title: "Latest Announcements",
        icon: TfiAnnouncement,

        href: "/announcement",
        tabview: "Arbitrage"
      },
      {
        title: "Content Management",
        icon: AiOutlineFileText,

        href: "/content-management",
        tabview: "Arbitrage"
      },
      {
        title: "Blogs & Media",
        icon: ImBlogger,

        href: "/blogs-media",
        tabview: "Arbitrage"
      },
      {
        title: "News Subscribers",
        icon: BiNews,

        href: "/new-subscription-list",
        tabview: "Arbitrage"
      },
      {
        title: "User Reports ",
        icon: AiFillFileText,

        href: "/report-list",
        tabview: "Arbitrage"
      },

      {
        title: "Change Password",
        icon: BiLockOpenAlt,

        href: "/change-password-email",
        tabview: "Arbitrage"
      },



    ],
  },
];
const sections1 = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiFillHome,
        href: "/sniper-dashboard",
        tabview: "Sniper"
      },
      {
        title: "Bot settings",
        icon: AiFillDashboard,
        href: "/bot-setting",
        tabview: "Sniper"
      },
      {
        title: "Transaction History",
        icon: FaSquarespace,
        href: "/sniper-transactions",
        tabview: "Sniper"
      },


    ],
  },
];
const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const renderedSections = tabView === "Arbitrage" ? sections : sections1;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">

            {renderedSections?.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location.pathname,
                  state: section.tabView,
                  setSelectedTab


                })}
              </List>
            ))}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog

            maxWidth="sm"
            fullWidth
            className={classes.dialog}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="#0B1426"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="#0B1426"
                  variant="contained"
                  onClick={() => {
                    sessionStorage.removeItem("adminToken");
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
