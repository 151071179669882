import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },

  {
    exact: true,
    path: "/kyc-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KycManagement/KycList")),
  },

  {
    exact: true,
    guard: true,
    path: "/subscription-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SubscriptionManage/Subscription")),
  },
  {
    exact: true,
    guard: true,
    path: "/new-subscription-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/NewLetterSubscribe/index")),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashboardManagement/Dashboard")),
  },

  {
    exact: true,
    path: "/view-kyc",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KycManagement/ViewKyc")),
  },

  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/UserList")),
  },
  {
    exact: true,
    path: "/binary-tree",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/binaryTree")),
  },

  {
    exact: true,
    path: "/email-change-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/EmailChangeRequest")),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/ViewUser")),
  },
  {
    exact: true,
    path: "/view-emai-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/ViewEmailUser")),
  },
  {
    exact: true,
    path: "/claim-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/ClaimRequest")),
  },
  {
    exact: true,
    path: "/withdraw-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/WithdrawManagement/WithdrawList")),
  },
  {
    exact: true,
    path: "/view-withdraw",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/WithdrawManagement/ViewWithdraw")),
  },
  {
    exact: true,
    path: "/view-claim-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/ViewClaimRequest")),
  },

  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },

  {
    exact: true,
    path: "/change-password-email",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassEmail")),
  },
  {
    exact: true,
    path: "/change-password-otp",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassOTP")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/content-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentMangemet/ContentList")),
  },


  {
    exact: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentMangemet/ViewContent")),
  },

  {
    exact: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentMangemet/EditContent")),
  },

  {
    exact: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/TransactionManagement/TransactionManagement")),
  },

  {
    exact: true,
    path: "/block-transaction",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/TransactionManagement/BlockWithdrawal")),
  },

  {
    exact: true,
    path: "/announcement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/LatestAnnouncement/LatestAnnouncement")),
  },
  {
    exact: true,
    path: "/view-announcement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/LatestAnnouncement/ViewAnnouncement")),
  },
  {
    exact: true,
    path: "/edit-announcement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/LatestAnnouncement/EditAnnouncement")),
  },
  {
    exact: true,
    path: "/add-announcement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/LatestAnnouncement/AddAnnouncement")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/blogs-media",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BlogsMedia/BlogsList")),
  },
  {
    exact: true,
    path: "/view-blogs",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BlogsMedia/ViewBlogs")),
  },
  {
    exact: true,
    path: "/edit-blogs",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BlogsMedia/EditBlogs")),
  },
  {
    exact: true,
    path: "/add-blogs",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BlogsMedia/AddBlogs")),
  },

  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/report-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserReports/ReportList")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },

  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },

  {
    exact: true,
    path: "/view-subscription",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/ViewSubscription")
    ),
  },

  {
    exact: true,
    // guard: true,
    path: "/subscription-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/Subscription")
    ),
  },

  {
    exact: true,
    // guard: true,
    path: "/add-subscription",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubscriptionManage/AddSubscibe")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },

];
