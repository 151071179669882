const url = "https://nodepune-liquidity.mobiloitte.io"; //staging
// const url = "https://node.chromeliquidity.org"; //live
// const url = "http://172.16.2.35:1050";
// const url = "http://172.16.2.38:3055";

const ApiConfig = {
  // login: `${url}/api/v1/user/login`,
  loginadmin: `${url}/api/v1/admin/login`,
  // signup: `${url}/api/v1/user/register`,
  // verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/api/v1/admin/verifyOTP`,
  // forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  // changePassword: `${url}/api/v1/user/changePassword`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  changePasswordAdmin: `${url}/api/v1/admin/changePassword`,
  // resend: `${url}/api/v1/user/resend`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  // profile: `${url}/api/v1/user/profile`,
  getProfile: `${url}/api/v1/admin/getProfile`,
  // editProfile: `${url}/api/v1/user/editProfile`,
  adminEditProfile: `${url}/api/v1/admin/editProfile`,
  uploadImage: `${url}/api/v1/upload/uploadImage`,

  acceptEmailRequest: `${url}/api/v1/admin/acceptEmailRequest`,
  rejectEmailRequest: `${url}/api/v1/admin/rejectEmailRequest`,
  listAllEmailRequest: `${url}/api/v1/admin/listAllEmailRequest`,
  viewemailreqDetail: `${url}/api/v1/admin/viewEmailReqOfParticularUser`,
  acceptKycRequest: `${url}/api/v1/kyc/acceptKycRequest`,
  rejectKycRequest: `${url}/api/v1/kyc/rejectKycRequest`,
  kyclist: `${url}/api/v1/kyc/listAllKycRequest`,
  viewKyc: `${url}/api/v1/kyc/viewKycDetail`,
  approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,
  listUser: `${url}/api/v1/admin/listUser`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/edit`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  delete: `${url}/api/v1/plan/delete/`,

  claimRewards: `${url}/api/v1/admin/getWithdrawalList`,
  viewClaimRewards: `${url}/api/v1/admin/viewWithdrawalList`,
  acceptClaimRequest: `${url}/api/v1/admin/adminApproveWithdrawal`,
  rejectClaimRequest: `${url}/api/v1/admin/adminApproveWithdrawal`,

  staticData: `${url}/api/v1/static/static`,
  staticDatavIew: `${url}/api/v1/static/static`,

  listAnnouncement: `${url}/api/v1/announcement/announcementContentList`,
  viewAnnouncement: `${url}/api/v1/announcement/viewannouncementContent`,
  editAnnouncement: `${url}/api/v1/announcement/editannouncementContent`,
  addAnnouncement: `${url}/api/v1/announcement/addannouncementContent`,

  newsLettersubscriberList: `${url}/api/v1/admin/newsLettersubscriberList`,

  addBlogs: `${url}/api/v1/blogMedia/addblogMedia`,
  listBlogs: `${url}/api/v1/blogMedia/getblogMediaList`,
  editBlogs: `${url}/api/v1/blogMedia/updateBlogMedia`,
  deleteBlog: `${url}/api/v1/blogMedia/deleteblogMedia`,

  userOverallRewardTotal: `${url}/api/v1/admin/userOverallRewardTotal`,
  userTotalSum: `${url}/api/v1/admin/userTotalSum`,
  getTwoFaDetails: `${url}/api/v1/admin/getTwoFADetails`,
  updateTwoFaDetails: `${url}/api/v1/admin/updateTwoFADetails`,
  sendOtpForLogin: `${url}/api/v1/admin/sendOTPLogin`,
  getLiqidityDashboard: `${url}/api/v1/admin/user-liquidity-dashboard`,
  getLiqidityDashboard: `${url}/api/v1/admin/user-liquidity-dashboard`,
  getAdminCRTTokenBalance: `${url}/api/v1/admin/getAdminCRTTokenBalance`,

  // userDetails
  getUserBuyPlanHistory: `${url}/api/v1/admin/getUserBuyPlanHistory`,
  userReportData: `${url}/api/v1/admin/userReportData`,
  viewuserKycDetail: `${url}/api/v1/admin/viewuserKycDetail`,
  viewUserWallet: `${url}/api/v1/admin/viewUserWallet`,
  getUserFiveStarBonusData: `${url}/api/v1/admin/getUserFiveStarBonusData`,
  getUserRankAndRewards: `${url}/api/v1/admin/getUserRankAndRewards`,
  getUserearningAffiliates: `${url}/api/v1/admin/getUserearningAffiliates`,
  getUserDashboardDirectBinaryIncentive: `${url}/api/v1/admin/getUserDashboardDirectBinaryIncentive`,
  getUserLiquidityRewards: `${url}/api/v1/admin/getLiquidityRewardsAdmin`,
  updateUserProfile: `${url}/api/v1/admin/updateUserProfile`,
  userTotalBusiness: `${url}/api/v1/admin/userTotalBusiness`,
  minWithdrawalAmount: `${url}/api/v1/admin/setMinWithdrawalAmount`,
  getMinWithdrawalAmount: `${url}/api/v1/admin/getMinWithdrawalAmount`,

  //report Mnagement
  userReportsList: `${url}/api/v1/admin/userReportsList`,
  userReportsCount: `${url}/api/v1/admin/userReportsCount`,

  //
  updateBlockUnblockWithdrawLiquidityRewards: `${url}/api/v1/admin/updateBlockUnblockWithdrawLiquidityRewards`,

  // user wallet
  viewWallet: `${url}/api/v1/admin/viewWallet`,
  updateWalletBalance: `${url}/api/v1/admin/updateWalletBalance`,
  loanApplyToUser: `${url}/api/v1/admin/loanApplyToUser`,

  // matching tree

  getBinaryTreeDetails: `${url}/api/v1/admin/getBinaryTreeDetails`,
  getStatisticsDetails: `${url}/api/v1/admin/getStatisticsDetails`,
  getUserByUsername: `${url}/api/v1/admin/getUserByUsername`,









};
export default ApiConfig;
